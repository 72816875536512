import request from '@/utils/request'

// 查询app版本列表
export function listVersion(query) {
  return request({
    url: '/app/version/list',
    method: 'get',
    params: query
  })
}

// 查询app版本详细
export function getVersion(id) {
  return request({
    url: '/app/version/' + id,
    method: 'get'
  })
}

// 新增app版本
export function addVersion(data) {
  return request({
    url: '/app/version',
    method: 'post',
    data: data
  })
}

// 修改app版本
export function updateVersion(data) {
  return request({
    url: '/app/version',
    method: 'put',
    data: data
  })
}

// 删除app版本
export function delVersion(id) {
  return request({
    url: '/app/version/' + id,
    method: 'delete'
  })
}
